import * as React from 'react';
import { TFF } from '@tff/types';
import { useTypedSelector } from '../../../../reducers';
import { weekday } from '../../../FlightDetailCard';
import { format } from 'date-fns';
import TFFTypography from '../../../../styled-components/TFFTypography';
import TFFIcon from '../../../../styled-components/TFFIcon';
import FareAttributeLine from './FareAttributeLine';

interface props {
  journey: TFF.Journey;
  icon?: string;
  capitalizedCabinClass: string;
  alignClassToRight?: boolean;
  isFlightDifferent?: boolean;
  selectedOfferAncillaries?: TFF.FlightAlternativesAncillaries;
}

const FlightStopsPath: React.FC<props> = ({ journey, icon, capitalizedCabinClass, alignClassToRight, isFlightDifferent, selectedOfferAncillaries }) => {
  const segments: TFF.Segment[] = journey.Segments;
  const lastSegment = segments[segments.length - 1];
  const firstSegment: TFF.Segment | undefined = segments[0];
  const { locale } = useTypedSelector(({ settings }) => settings);

  const formatDate = (t: string): string | null => {
    if (t) {
      return format(new Date(t), 'dd.MM.yyyy');
    }

    return null;
  };

  const getLabels = () => {
    const labels = segments.map((s, index) => ({
      label: s.Origin,
      hour: new Intl.DateTimeFormat('de-DE', { timeStyle: 'short' }).format(new Date(firstSegment.DepartureTime)),
      date: `${weekday(lastSegment?.ArrivalTime, locale.locale).substring(0, 2)}. ${formatDate(
        lastSegment?.DepartureTime,
      )}`,
    }));

    labels.push({
      label: lastSegment.Destination,
      hour: new Intl.DateTimeFormat('de-DE', { timeStyle: 'short' }).format(new Date(lastSegment.ArrivalTime)),
      date: `${weekday(lastSegment?.ArrivalTime, locale.locale).substring(0, 2)}. ${formatDate(
        lastSegment?.ArrivalTime,
      )}`,
    });

    return labels;
  };

  const labels = getLabels();
  const labelsLength = labels.length;
  const initialLabel = labels[0];
  const finalLabel = labels[labelsLength - 1];
  const numberOfStops = labelsLength - 2;

  return (
    <div className="tff-d-flex" style={alignClassToRight ? { justifyContent: 'space-between' } : undefined}>
      {icon && (
        <span className="tff-journey-icon-container">
          <TFFIcon iconName={icon} />
        </span>
      )}
      <div className="tff-journey-details">
        <div>
          <TFFTypography text={initialLabel.label} fontWeight="semibold" color="color-theme-variant" />
          <TFFTypography text={initialLabel.hour} fontWeight="bold" color={isFlightDifferent ? "color-functional-error-600" : "color-functional-neutral-900"} />
          <TFFTypography text={initialLabel.date} color={isFlightDifferent ? "color-functional-error-600" : "color-functional-neutral-900"} />
        </div>
        <div className="tff-horizontal-line-pos">
          <div className="tff-horizontal-line-container stops">
            <div className="tff-d-flex">
              <hr className="tff-horizontal-line stops" />
              <div className="tff-circle"></div>
              <hr className="tff-horizontal-line stops" />
            </div>
            {numberOfStops > 0 ? (
              <TFFTypography text={`${numberOfStops} ${numberOfStops > 1 ? 'Stops' : 'Stop'}`} size="small" />
            ) : (
              <TFFTypography text="Direct" size="small" />
            )}
          </div>
        </div>
        <div>
          <TFFTypography text={finalLabel.label} fontWeight="semibold" color="color-theme-variant" />
          <TFFTypography text={finalLabel.hour} fontWeight="bold" color={isFlightDifferent ? "color-functional-error-600" : "color-functional-neutral-900"} />
          <TFFTypography text={finalLabel.date} color={isFlightDifferent ? "color-functional-error-600" : "color-functional-neutral-900"} />
        </div>
      </div>
      <div className="tff-d-flex" style={{ flexDirection: 'column', gap: '8px', alignItems: 'flex-start' }}>
        <div className="tff-cabin-class-container">
          <span className="tff-cabin-class-icon">
            <TFFIcon iconName="seat" />
          </span>
          <TFFTypography text={capitalizedCabinClass} color="color-functional-neutral-900" />
        </div>
        {selectedOfferAncillaries?.Journeys[journey.Id].map((fareAttribute, index) => !fareAttribute.Id.includes("SKY") && !fareAttribute.Id.includes("LOUNGE_ACCESS") && <FareAttributeLine key={index} fareAttributeStr={fareAttribute.Description} />)}
      </div>
    </div>
  );
};

export default FlightStopsPath;
