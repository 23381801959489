import React from 'react';
import TFFIcon from '../../../../styled-components/TFFIcon';
import TFFTypography from '../../../../styled-components/TFFTypography';

interface FareAttributeLineProps {
    fareAttributeStr: string;
}

const FareAttributeLine: React.FC<FareAttributeLineProps> = ({ fareAttributeStr }) => {
    return (
        <div className="tff-d-flex tff-cabin-class-container" style={{ alignItems: 'center', gap: '4px' }}>
            <span className="tff-cabin-class-icon">
                <TFFIcon iconName="exclamation-mark-circled" />
            </span>
            <TFFTypography text={fareAttributeStr} color="color-functional-neutral-900" />
        </div>
    );
};

export default FareAttributeLine;